// 授权证书
<template>
    <div>
        <van-grid :gutter="10" :border="false" :column-num="3" class="video-list">
            <van-grid-item  v-for="item in certificateType" :key="item.id" :item="item"  :to="{name: 'certificateList', params: { certificateId: item.certificateListArr }}">
                <van-image :src="require('../../../assets/img/video/certificate.png')" />
                <span>{{item.idTypeName}}</span>
            </van-grid-item>
        </van-grid>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                certificateType: [{
                    "idType": 1,
                    "idTypeName" : "授权证书",
                    // "certificateListArr":"拓普康一级代理|/sqzs/1.jpg,天宇授权证书|/sqzs/2.jpg"
                    "certificateListArr": "司南导航授权书|/sqzs/1.png,中海达授权书|/sqzs/2.png,千寻位置分销商授权书|/sqzs/3.png,天宇光电授权书|/sqzs/4.png,中纬授权书|/sqzs/5.png,天硕代理商授权书|/sqzs/6.png,中海达授权书|/sqzs/7.png,天津欧波特约经销商授权书|/sqzs/8.png,拓普康一级代理授权书|/sqzs/9.jpg,UFO授权书|/sqzs/10.png,天宇光电授权证书|/sqzs/11.png,中纬授权书|/sqzs/12.png,三鼎光电授权书|/sqzs/13.png"
                    
                }]
            };
        }
    }
  
</script>
<style lang="less" scoped>
    .video-list span {
        font-size: 26px;
        margin-top: 20px;
        height: 76px;
    }
    .van-grid-item /deep/ .van-grid-item__content {
        padding-bottom: 0;
        border-radius: 10px!important;
    }
</style>